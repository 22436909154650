import { createApp } from 'vue'
import { Collapse, Modal, Tab } from 'bootstrap' // abilita il js dell'accordion
import mitt from 'mitt';
import router from './presentationRouter'
import store from './store'

import PresentationApp from './presentationApp.vue'
import './presentationMain.scss'

const emitter = mitt();

const app = createApp(PresentationApp)

router.$app = app;
app.use(router)

store.$app = app;
app.use(store)

app.config.globalProperties.emitter = emitter;

const vm = app.mount('#presentation-app')
